import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['menu', 'menuItem', 'subMenu', 'scrollContainer']

  hideMenu(event) {
    if (!this.hasMenuTarget) {
      return false;
    }

    let target = event.target;
    let openMenu = document.querySelectorAll('.context-menu:not(.hidden)');

    if (!openMenu.length || target.classList.contains('context-menu-icon')) {
      return false;
    }

    if (this._clickIsOutsideOfMenu(target, openMenu)) {
      openMenu[0].remove();
      document.getElementById(openMenu[0].id).classList.remove('open');

      this._closeSubMenus();
      this._resetMenuItems()
      this._resetScrollContainers();
    }
  }

  toggleMenu(event) {
    let currentMenu;

    if (event.target.classList.contains('context-menu-item')) {
      let menuId = event.target.closest('.context-menu').id;
      currentMenu = document.querySelector(`#${menuId}:not(.clone)`)
    } else {
      currentMenu = event.target.closest('.context-menu-opener').querySelector('.context-menu');
    }

    let openMenu= document.querySelectorAll('.context-menu:not(.hidden)');

    if (openMenu.length && openMenu[0].id !== currentMenu.id) {
      openMenu[0].remove();
      document.getElementById(openMenu[0].id).classList.remove('open');
    }

    this._closeSubMenus();
    this._resetMenuItems();
    this._resetScrollContainers();

    let scrollContainer = currentMenu.closest('[data-context-menu-target="scrollContainer"]');

    if (currentMenu.classList.contains('open')) {
      document.getElementById(currentMenu.id).remove();
      currentMenu.classList.remove('open');

      if (scrollContainer) {
        scrollContainer.classList.add('overflow-y-auto');
        scrollContainer.classList.remove('overflow-y-hidden');
      }

      return false;
    }

    if (scrollContainer) {
      scrollContainer.classList.remove('overflow-y-auto');
      scrollContainer.classList.add('overflow-y-hidden');
    }

    currentMenu.classList.add('open');

    let parentCoords = currentMenu.closest('.relative').getBoundingClientRect();
    let copy = currentMenu.cloneNode(true);
    copy.style.top = `${parentCoords.bottom}px`;
    copy.style.left = `${parentCoords.right + 12}px`;
    copy.classList.add('clone');
    copy.classList.remove('hidden');

    document.body.prepend(copy);

    if (parentCoords.right + copy.clientWidth > window.innerWidth) {
      copy.style.left = `${parentCoords.right - copy.clientWidth}px`;
    } else {
      let copyCoords = copy.getBoundingClientRect();
      copy.style.left = `${copyCoords.left - copyCoords.width}px`;
    }

    if (parentCoords.top + copy.clientHeight > window.innerHeight) {
      copy.style.left = `${parentCoords.right - copy.clientWidth - parentCoords.width}px`;
      copy.style.top = `${window.innerHeight - copy.clientHeight}px`;
    }
  }

  displaySubmenu(event) {
    let target = event.target;

    if (target !== event.currentTarget) {
      return false;
    }

    let scrollContainer = target.closest('[data-context-menu-target="scrollContainer"]');
    let closeTargetAction = target.classList.contains('open');

    document.querySelectorAll('.context-menu-item').forEach((menu) => {
      if (menu.classList.contains('open')) {
        let submenu = menu.querySelector('[data-context-menu-target="subMenu"]');
        document.getElementById(submenu.id).remove();
        menu.classList.remove('open');

        if (scrollContainer) {
          scrollContainer.classList.add('overflow-y-auto');
          scrollContainer.classList.remove('overflow-y-hidden');
        }
      }
    });

    if (closeTargetAction) {
      return false;
    }

    if (scrollContainer) {
      scrollContainer.classList.remove('overflow-y-auto');
      scrollContainer.classList.add('overflow-y-hidden');
    }

    target.classList.add('open');

    let parentCoords = target.closest('.relative').getBoundingClientRect();
    let submenu = target.querySelector('[data-context-menu-target="subMenu"]');

    let copy = submenu.cloneNode(true);
    copy.style.top = `${parentCoords.top}px`;
    copy.style.left = `${parentCoords.right + 12}px`;
    copy.classList.add('clone');

    if (target.dataset.showSubmenuDirectly === 'true') {
      copy.classList.remove('hidden');
    }

    document.body.prepend(copy);

    if (parentCoords.right + copy.clientWidth > window.innerWidth) {
      copy.style.left = `${parentCoords.right - copy.clientWidth - parentCoords.width - 12}px`;
    }

    if (parentCoords.top + copy.clientHeight > window.innerHeight) {
      copy.style.top = `${window.innerHeight - copy.clientHeight}px`;
    }
  }

  _clickIsOutsideOfMenu(target, openMenu) {
    let menuContainsTarget = openMenu[0].contains(target);
    let subMenuContainsTarget = false;

    this.subMenuTargets.forEach((menu) => {
      if (menu.contains(target)) {
        subMenuContainsTarget = true;
      }
    });

    return (!menuContainsTarget && !subMenuContainsTarget);
  }

  _closeMainMenus() {
    this.menuTargets.forEach((menu) => {
      if (menu.classList.contains('clone')) {
        menu.remove();
      }
    });
  }

  _closeSubMenus() {
    this.subMenuTargets.forEach((menu) => {
      if (menu.classList.contains('clone')) {
        menu.remove();
      }
    });
  }

  _resetMenuItems() {
    this.menuItemTargets.forEach((item) => {
      item.classList.remove('open');
    });
  }

  _resetScrollContainers() {
    this.scrollContainerTargets.forEach((container) => {
      container.classList.remove('overflow-y-hidden');
      container.classList.add('overflow-y-auto');
    });
  }
}
