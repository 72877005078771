import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    window.addEventListener('storage', this._checkState.bind(this));

    this._checkState();
  }

  disconnect() {
    window.removeEventListener('storage', this._checkState.bind(this));
  }

  _checkState() {
    const value = localStorage.getItem('admin_sidebar_collapsed');

    if (value === 'true') {
      this._handleTrueState();
    } else if (value === 'false') {
      this._handleFalseState();
    }
  }

  _handleTrueState() {
    this.containerTarget.classList.add('sidebar-closed');
    this._toastMessageContainer().classList.add('sidebar-closed');

    if (this._editorContainer()) {
      this._editorContainer().classList.add('sidebar-closed');
    }
  }

  _handleFalseState() {
    this.containerTarget.classList.remove('sidebar-closed');
    this._toastMessageContainer().classList.remove('sidebar-closed');

    if (this._editorContainer()) {
      this._editorContainer().classList.remove('sidebar-closed');
    }
  }

  _toastMessageContainer() {
    return document.getElementById('toast-message-container-admin');
  }

  _editorContainer() {
    return document.getElementById('editor-container');
  }
}
