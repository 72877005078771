import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['container', 'content'];

  static values = {
    programId: String,
    contentPageId: String
  }

  connect() {
    document.addEventListener('promote:open-import-editor', this._openEditor.bind(this));
    document.addEventListener('promote:close-import-editor', this.closeEditor.bind(this));
  }

  disconnect() {
    document.removeEventListener('promote:open-import-editor', this._openEditor.bind(this));
    document.removeEventListener('promote:close-import-editor', this.closeEditor.bind(this));
  }

  closeEditor() {
    this.containerTarget.classList.add('closed');
    this.contentTarget.innerHTML = '';
    document.dispatchEvent(new CustomEvent('promote:shortcuts--unregister', {
      detail: { group: 'editor' }
    }));
  }

  _openEditor(event) {
    if (this._sidebarCollapsed()) {
      this.containerTarget.classList.add('sidebar-closed');
    }

    this.containerTarget.classList.remove('initial-state');
    this.containerTarget.classList.remove('closed');
  }

  _sidebarCollapsed() {
    return localStorage.getItem(`admin_sidebar_collapsed`) === 'true';
  }
}