import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

export default class extends Controller {
  static values = {
    programId: Number,
    programModuleId: Number,
    contentPageId: Number
  }


  loadMoveMenu(event) {
    if (event.target !== event.currentTarget || event.target.classList.contains('open')) {
      return false;
    }

    this._loadData(this._moveMenuUrl());
  }

  loadMoveAfterMenu(event) {
    this._loadData(this._moveAfterMenuUrl());
  }

  selectCard() {
    let selectedCards = document.querySelectorAll('.js-overview-module .selected');

    selectedCards.forEach((card) => {
      card.classList.remove('selected');
    });

    let menuId = event.target.closest('.context-menu').id;
    document.querySelector(`#${menuId}:not(.clone)`).closest("[id*='js-overview-module-card-content-']").classList.add('selected');
  }

  _moveMenuUrl() {
    let baseUrl = `/admin/programs/${this.programIdValue}/program_modules/move`
    let url;

    if (this.hasProgramModuleIdValue) {
      url = `${baseUrl}?module_id=${this.programModuleIdValue}`;
    } else if (this.hasContentPageIdValue) {
      url = `${baseUrl}?content_page_id=${this.contentPageIdValue}`;
    }

    return url;
  }

  _moveAfterMenuUrl() {
    return `/admin/programs/${this.programIdValue}/content_pages/${this.contentPageIdValue}/move`;
  }

  _loadData(url, callback) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: url,
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        if (callback) callback();
      }
    })
  }
}
