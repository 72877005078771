import { Controller } from "@hotwired/stimulus"
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ['overlay', 'spinner']

  onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.target;
    const formData = new FormData(form);

    const action = form.action;
    const method = form.method;

    ajax({
      url: action,
      type: method,
      data: formData,
      beforeSend: (xhr) => {
        this._showLoadOverlay()

        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        this._hideLoadOverlay();
      },
      error: () => {
        this._hideLoadOverlay();
      }
    });
  }

  _showLoadOverlay() {
    this.spinnerTarget.classList.remove("hidden");
    this._showOverlay();
  }

  _hideLoadOverlay() {
    this.spinnerTarget.classList.add("hidden");
    this._hideOverlay();
  }

  _showOverlay() {
    this.overlayTarget.classList.remove("hidden");
  }

  _hideOverlay() {
    this.overlayTarget.classList.add("hidden");
  }
}
